import React, { useContext, useEffect, useState, Redirect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Button, Tabs, Tab, DropdownButton, Alert, ProgressBar, Dropdown, Badge, Modal, Form, Offcanvas, Spinner, Nav} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify/dist/react-toastify';
import { ControllerPump } from './Pump';
import { ControllerValve } from './Valve';
import { ControllerButton } from './Button';
import { ControllerFlowSensor } from './FlowSensor';
import { Printer } from './Printer';
import { ControllerFilling } from './Filling';
import { GsmModem } from './GsmModem';
import { Metrolog } from './Metrolog';
import { Indicator } from './Indicator';
import { Livenka } from './Smart/Livenka';
import { Topaz } from './Smart/Topaz';
import { Gilbarco } from './Smart/Gilbarco';
import { ExternalInput } from './ExternalInput';
import { Tokheim } from './Smart/Bs2v2/Tokheim';
import { Auth } from './Auth';
import { Sections } from './Sections';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import * as signalR from "@microsoft/signalr"
import StatusConnect01 from "../../img/image_connect/StatusConnect-01.png";
import StatusConnect02 from "../../img/image_connect/StatusConnect-02.png";
import StatusConnect03 from "../../img/image_connect/StatusConnect-03.png";
import StatusConnect04 from "../../img/image_connect/StatusConnect-04.png";
import StatusConnect05 from "../../img/image_connect/StatusConnect-05.png";
import StatusConnect06 from "../../img/image_connect/StatusConnect-06.png";
import StatusConnect07 from "../../img/image_connect/StatusConnect-07.png";
import StatusConnect08 from "../../img/image_connect/StatusConnect-08.png";
import StatusConnect09 from "../../img/image_connect/StatusConnect_09.png";
import StatusConnect009 from "../../img/image_connect/StatusConnect-09.png";
import StatusConnect10 from "../../img/image_connect/StatusConnect-10.png";
import api from '../../utils/axiosInterceptions';

export const MainInfoBenzaController = ({translated, translatedForm}) => {
    const formatChars = {
        '*': '[A-Fa-f0-9]'
      };
      const router = useHistory()
      const params = useParams();
      const {isAuth} = useContext(AuthContext);
      const { control, register, handleSubmit, reset, setValue, formState, formState: { errors }} = useForm();
      const [isLoading, setIsLoading] = useState(false)
      const [isGetConfig, setIsGetConfig] = useState(false)
      const [isAutoRead, setIsAutoRead] = useState(false)
      const [signalrConnection, setSignalrConnection] = useState({})
      const [textActionRead, setTextActionRead] = useState('')
      const [btnUpdateMainInfo, setBtnUpdateMainInfo] = useState(false)
      const [buttonReadSettings, setButtonReadSettings] = useState(false)
      const [job, setJob] = useState(0)
      const [showReadController, setShowReadController] = useState(false)
      const [showWriteController, setShowWriteController] = useState(false)
      const [currentActiveTab, setCurrentActiveTab] = useState('main')
      const [periodLevelMeterRead, setPeriodLevelMeterRead] = useState(0)
      const [showModalChangeType, setShowModalChangeType] = useState(false)
      const [typeController, setTypeController] = useState(0)
      const [statusConnect, setStatusConnect] = useState('')
      const [checkboxReadJournal, setCheckBoxReadJournal] = useState(true)
      const [checkboxReadUserLimit, setCheckboxReadUserLimit] = useState(false)
      const [checkboxWriteSetting, setCheckboxWriteSetting] = useState(true)
      const [checkboxWriteTime, setCheckboxWriteTime] = useState(true)
      const [checkboxWriteUserLimit, setCheckboxWriteUserLimit] = useState(false)
      const [checkboxTable, setCheckboxTable] = useState(false)
      const [countDispenser, setCountDispenser] = useState(0)
      const [listSmartData, setListSmartData] = useState([])
      const [listRfid, setListRfid] = useState([])
      const defaultTextButtonController = translated.ActionWithControllers
      const [smartData, setSmartData] = useState({
        subtype:0,
        address:0,
        address2:0,
        address3:0,
        address4:0,
        address5:0,
        address6:0,
        address7:0,
        address8:0,
        fuel_type:0,
        fuel_type1:0,
        fuel_type2:0,
        fuel_type3:0,
        fuel_type4:0,
        fuel_type5:0,
        fuel_type6:0,
        fuel_type7:0,
        fuel_type8:0,
        countParty:0,
        countTypeFuel:0
      })
      const [selectedCompany, setSelectedCompany] = useState({
        label:'',
        value:''
      })
      const [selectedTrkWork, setSelectedTrkWork] = useState({
        label:'',
        value:''
      })
      const [selectedTypePanel, setSelectedPanel] = useState({
        label:'',
        value:0
      })
      const [selectedTopaz, setSelectedTopaz] = useState({
        label:'',
        value:0
      })
      const [selectedModeFilling, setSelectedModeFilling] = useState({
        label:'',
        value:0
      })
      const [selectedModeRfid, setSelectedModeRfid] = useState({
        label:'',
        value:0
      })
      const [selectedModeIntake, setSelectedModeIntake] = useState({
        label:'',
        value:0
      })
      const [selectedScanning, setSelectedScanning] = useState({
        label:'',
        value:0
      })
      const [progressBarJournal, setProgressBarJournal] = useState(0)
      const [textButtonController, setTextButtonController] = useState(defaultTextButtonController)
      const [listCompanies, setListCompanies] = useState([])
      const [controllerInfo, setControllerInfo] = useState({
        connectId: 0,
        id:0,
        type:0,
        companyId:0,
        stationId:0,
        hasModem:false,
        dayManufacture:0,
        firmwareVersionString: null
      })
      const [loadingBtn, setLoadingBtn] = useState({
        save:false,
        controllerBtn:false
      })
    
      const listBooleans = [
        {label:translated.Yes, value:1},
        {label:translated.No, value:0}
      ]
    
      const listBoolean = [
        {label:translated.Yes, value:true},
        {label:translated.No, value:false}
      ]
    
      const listStopMode = [
        {label:translated.Yes, value:true},
        {label:translated.No, value:false}
      ]
    
      const listPanel = [
        {label:"Benza", value:0},
        {label:"Kamka", value:1}
      ]

      const listTrkWork = [
        {label: translated.Yes, value:0},
        {label: translated.No, value:1}
      ]
    
      const listRfidValues = [
        {label:"Benza Mifare", value:0},
        {label:"Benza EM Marine", value:1},
        {label: translated.Inforcom, value:2},
        {label:"Benza-NFC", value:3}
      ]
    
      const listTypeWork = [
        {label: translated.SelfFilling, value:0},
        {label: translated.RefuelingComputer, value:1},
        {label: translated.OfflineComputer, value:2},
        {label: translated.SvoyClub, value:3},
      ]

    const displayToast = (message, status) => {

        if(status){
          toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
    
        } else {
          toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme:"dark"
          });
        }
      
    }

    const handleConnectId = (item) => {
      setControllerInfo({connectId: item.target.value})
      setValue("ConnectId", controllerInfo.connectId)
    }

    const fetchCompanies = (company_id) => {

      api.get("/api/company/details/stations", {
          headers:{
            "content-type" : "application/json",
            "CompanyId": company_id
          },
          params:{
            CompanyId: company_id,
          },
          withCredentials:true
      })
      .then(function (response) {
          const addCompany = response.data.listDatas.map(item => ({
              ...item,
              value: item.id,
              label: item.name
          }))
          setListCompanies(addCompany)
      })
      .catch(function (error) {
          console.log(error);
      });

    }    
      
    const controllerData = () => {
        api.get("/api/controllers/details", {
          headers:{
              "content-type" : "application/json",
              "controllerId":params.id
          },
          params:{
              controllerId: params.id,
          },
          withCredentials:true
        })
        .then(function (response) 
        {
          fetchCompanies(response.data.dataResult.companyId)

          setSmartData({
            subtype: response.data.dataResult.smart.subType,
            address: response.data.dataResult.smart.address,
            address2: response.data.dataResult.smart.address2,
            address3: response.data.dataResult.smart.address3,
            address4: response.data.dataResult.smart.address4,
            address5: response.data.dataResult.smart.address5,
            address6: response.data.dataResult.smart.address6,
            address7: response.data.dataResult.smart.address7,
            address8: response.data.dataResult.smart.address8,
            fuel_type: response.data.dataResult.fuel.id,
            fuel_type2: response.data.dataResult.smart.fuelType2,
            fuel_type3: response.data.dataResult.smart.fuelType3,
            fuel_type4: response.data.dataResult.smart.fuelType4,
            fuel_type5: response.data.dataResult.smart.fuelType5,
            fuel_type6: response.data.dataResult.smart.fuelType6,
            fuel_type7: response.data.dataResult.smart.fuelType7,
            fuel_type8: response.data.dataResult.smart.fuelType8,
            countParty: response.data.dataResult.smart.countParty,
            countTypeFuel: response.data.dataResult.smart.countTypeFuel
        })

        setCountDispenser(response.data.dataResult.countDispenser)
        setListSmartData(response.data.dataResult.smarts)
        
        setControllerInfo({
            connectId: response.data.dataResult.connectId,
            id: response.data.dataResult.id,
            type:response.data.dataResult.enumControllerType.id,
            companyId: response.data.dataResult.companyId,
            stationId: response.data.dataResult.stationId,
            hasModem: response.data.dataResult.hasModem,
            dayManufacture: response.data.dataResult.dayManufacture,
            firmwareVersionString: response.data.dataResult.firmwareVersionBSString
        })
        setValue("Station", {
            label: response.data.dataResult.station.name,
            value: response.data.dataResult.station.id
        })
        setPeriodLevelMeterRead(response.data.dataResult.periodLevelMeterRead)

        //Конфигурация - Механика, Топаз, Ливенка и тд
        setTypeController(response.data.dataResult.type)

        let modeIntake = response.data.dataResult.modeIntake ? 1 : 0;
        let findModeIntake = listBooleans.filter((item) => item.value == modeIntake)
        setValue("ModeIntake", {label: findModeIntake[0].label, value:findModeIntake[0].value})

        let findWorking = listStopMode.filter((item) => item.value == response.data.dataResult.stopMode == 0 ? true : false)
        console.log("find working")
        console.log(findWorking)
        setValue("StopMode", {label: findWorking[0].label, value:findWorking[0].value})

        let findDoScanning = listStopMode.filter((item) => item.value == response.data.dataResult.doScanning)
        setValue("DoScanning", {label: findDoScanning[0].label, value:findDoScanning[0].value})
        setIsAutoRead(findDoScanning[0].value)

        let findOnIndAndKey = listBooleans.filter((item) => item.value == response.data.dataResult.onIndAndKey)
        setValue("OnIndAndKey", {label: findOnIndAndKey[0].label, value:findOnIndAndKey[0].value})
        
        let findRfid = listRfidValues.filter((item) => item.value == response.data.dataResult.smart.modeRfid)
        setValue("ModeRfid", {label: findRfid[0].label, value:findRfid[0].value})

        if(response.data.dataResult.enumControllerType.id == 3)
        {
          setListRfid(listRfidValues.filter((item) => item.value == 3 || item.value == 1))
        }
        else
        {
          setListRfid(listRfidValues)
        }

        if(response.data.dataResult.enumControllerType.id != 3)
        {
          let findPanel = listPanel.filter((item) => item.value === response.data.dataResult.interfacePanel)
          setValue("InterfacePanel", {label: findPanel[0].label, value:findPanel[0].value})
          
          let topaz = response.data.dataResult.smart.modeTopaz20 ? 1 : 0;
          let findModeTopaz = listBooleans.filter((item) => item.value === topaz)
          setValue("ModeTopaz", {label: findModeTopaz[0].label, value:findModeTopaz[0].value})
        }

        let findWork = listTypeWork.filter((item) => item.value == response.data.dataResult.smart.modeFilling)
        setValue("ModeFilling", {label: findWork[0].label, value:findWork[0].value})

        setValue("Name", response.data.dataResult.name)
        setValue("ConnectId", response.data.dataResult.connectId)
        setValue("Address", response.data.dataResult.address)
        setValue("VersionSoftware", response.data.dataResult.firmwareVersionString)
        setValue("VersionProtocol", response.data.dataResult.versionProtocol)
        setValue("EnumControllerType", response.data.dataResult.enumControllerType.name)
        setIsLoading(false)

        if(response.data.dataResult.statusConnect == -1 || response.data.dataResult.statusConnect == -2 || response.data.dataResult.statusConnect == -99 || response.data.dataResult.statusConnect == -97 
            || response.data.dataResult.statusConnect == -96 || response.data.dataResult.statusConnect == -95 || response.data.dataResult.statusConnect == -93)
        {
            setStatusConnect(<img className="d-flex justify-content-center" src={StatusConnect06} />)
        }

        if(response.data.dataResult.statusConnect == -98) {
            setStatusConnect(<img src={StatusConnect07} />)
        }

        if(response.data.dataResult.statusConnect == -94) {
            setStatusConnect(<img src={StatusConnect10} />)
        }

        if(response.data.dataResult.statusConnect >= 0 && response.data.dataResult.statusConnect <= 4) {
            setStatusConnect(<img src={StatusConnect05} />)
        }

        if(response.data.dataResult.statusConnect > 4 && response.data.dataResult.statusConnect <= 9) {
            setStatusConnect(<img src={StatusConnect04} />)
        }

        if(response.data.dataResult.statusConnect > 9 && response.data.dataResult.statusConnect <= 16) {
            setStatusConnect(<img src={StatusConnect03} />)
        }

        if(response.data.dataResult.statusConnect > 16 && response.data.dataResult.statusConnect <= 21) {
            setStatusConnect(<img src={StatusConnect02} />)
        }

        if(response.data.dataResult.statusConnect > 21 && response.data.dataResult.statusConnect <= 31) {
            setStatusConnect(<img src={StatusConnect01} />)
        }

        if(response.data.dataResult.statusConnect == 99) {
            setStatusConnect(<img src={StatusConnect06} />)
        }

        if(response.data.dataResult.statusConnect == 128) {
            setStatusConnect(<img src={StatusConnect09} />)
        }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const handleActiveTab = data => {
        setCurrentActiveTab(data)
    }

    useEffect(() => {
        setIsLoading(true)
        controllerData()
    }, [])
      
    const onSubmit = (data) => {
        console.log("controller update")
        console.log(data)
        setBtnUpdateMainInfo(true)
        api.post("/api/controllers/details/update", {
          Name: data.Name,
          Address: data.Address,
          ConnectId: data.ConnectId,
          ModeIntake: data.ModeIntake.value,
          DoScanning: data.DoScanning.value,
          ModeFilling: data.ModeFilling.value,
          ModeTopaz: controllerInfo.type != 3 ? data.ModeTopaz.value : 0,
          ModeRfid: data.ModeRfid.value,
          StopMode: controllerInfo.type != 3 ? data.StopMode.value : false,
          StationId: data.Station.value,
          InterfacePanel: controllerInfo.type != 3 ? data.InterfacePanel.value : 0,
          IsUpdateLevelMeter: false,
          Id: controllerInfo.id,
          OnIndAndKey: controllerInfo.type != 3 ? 0 : data.OnIndAndKey.value
        })
        .then(function (response) {
          setBtnUpdateMainInfo(false)
          displayToast(response.data.message, response.data.isSuccess)
        })
        .catch(function (error) {
          console.log(error)
          setBtnUpdateMainInfo(false)
          displayToast(error.response.data.errors.errorMessage, error.response.data.isSuccess)
        });
    }

    return(
        <div className="row mt-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey="main">

          <div className="col-xs-12 col-lg-3 col-md-3">
            {
              isLoading
              ? <Skeleton width={220} height={30} className="mb-2" count={10} />
              : <Nav variant="pills" onClick={(e) => handleActiveTab(e.target.dataset.rrUiEventKey)} className="flex-column">
                  <Nav.Item>
                      <Nav.Link eventKey="main">
                          {translated.General}
                      </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="sections">
                          {translated.Sections}
                      </Nav.Link>
                  </Nav.Item>

                  {                    
                    controllerInfo.type != 3
                    ? typeController == 1 || typeController == 0
                      ?
                      <>
                      <Nav.Item>
                        <Nav.Link eventKey="nasos">
                            {translated.Pump}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="valve">
                            {translated.Valves}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="flow-sensor">
                            {translated.FlowSensor}
                        </Nav.Link>
                      </Nav.Item>
  
                      {
                        controllerInfo.type == 3
                        ?
                        <Nav.Item>
                          <Nav.Link eventKey="external-input">
                              {translated.ExternalInputs}
                          </Nav.Link>
                        </Nav.Item>
                        : null
                      }
  
                      {/*
                        controllerInfo.type == 2
                        ?
                        <Nav.Item>
                          <Nav.Link eventKey="printer">
                            {translated.PrinteCheque}
                          </Nav.Link>
                        </Nav.Item>
                        : null
                      */}
                      </>
                      :   
                      <Nav.Item>
                        <Nav.Link eventKey="param-trk">
                            {translated.Params}
                        </Nav.Link>
                      </Nav.Item>
                    : typeController == 0
                      ?
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="nasos">
                              {translated.Pump}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="valve">
                              {translated.Valves}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="flow-sensor">
                              {translated.FlowSensor}
                          </Nav.Link>
                        </Nav.Item>    
                        <Nav.Item>
                          <Nav.Link eventKey="external-input">
                              {translated.ExternalInputs}
                          </Nav.Link>
                        </Nav.Item>                        
                      </>
                      : typeController == 1 || typeController == 2 || typeController == 3 || typeController == 4
                        ?
                        <>
                          <Nav.Item>
                            <Nav.Link eventKey="param-trk">
                                {translated.Params}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="external-input">
                                {translated.ExternalInputs}
                            </Nav.Link>
                          </Nav.Item>
                        </>
                        : null
                  }

                  <Nav.Item>
                      <Nav.Link eventKey="metrolog">
                          {translated.Metrology}
                      </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="doze">
                          {translated.Filling}
                      </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                      <Nav.Link eventKey="button-start-stop">
                        {translated.ButtonStartStop}
                      </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="printer">
                      {translated.PrinteCheque}
                    </Nav.Link>
                  </Nav.Item>
                  {
                    controllerInfo.type != 3
                    ?
                    <Nav.Item>
                      <Nav.Link eventKey="indicator">
                        {translated.Indicators}
                      </Nav.Link>
                    </Nav.Item>
                    : null
                  }

                  <Nav.Item>
                      <Nav.Link eventKey="gsm_modem">
                        {translated.Modem}
                      </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                      <Nav.Link eventKey="auth">
                        {translated.Auth}
                      </Nav.Link>
                  </Nav.Item>
              </Nav>
            }
          </div>

          <div className="col-xs-12 col-lg-9 col-md-9">
              <Tab.Content>
                 
                  <Tab.Pane eventKey="main">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-xs-12 col-lg-5 col-md-5">

                              {
                                isLoading
                                ? <Skeleton height={30} count={6} className="mb-4" />
                                :
                                <>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.Name} <sup className="text-danger">*</sup></Form.Label>
                                    <Form.Control 
                                        {...register("Name")}
                                        type="text" maxLength="100" isInvalid={errors.Name}  
                                        placeholder={translatedForm.EnterValue(100)} className="form-control"  />
                                        {errors.Name && 
                                        <span className="text-danger font-size-13">{errors.Name.message}</span>
                                        }
                                  </Form.Group>

                                    <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.ID} <sup className="text-danger">*</sup></Form.Label>

                                    <Controller
                                        name="ConnectId"                                
                                        control={control}
                                        rules={{ required: translatedForm.Required}}
                                        render={({ field }) => (
                                            <InputMask 
                                                mask="******** ******** ********"
                                                className="form-control"
                                                type="text"
                                                formatChars={formatChars}
                                                defaultValue={controllerInfo.connectId}
                                                value={controllerInfo.connectId}
                                                placeholder={translated.Write}
                                                onChange={handleConnectId}
                                            />
                                        )}
                                    />
                                    {errors.ConnectId && 
                                        <span className="text-danger font-size-13">{errors.ConnectId.message}</span>
                                    }
                                    </Form.Group>

                                    {/*<Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.Address} <sup className="text-danger">*</sup></Form.Label>
                                    <Form.Control 
                                      {...register("Address", {
                                        minLength : {
                                          value: 1,
                                          message: translatedForm.MinNumber(1)
                                        },
                                        maxLength : {
                                          value: 255,
                                          message: translatedForm.MaxNumber(255)
                                        },
                                      })}
                                      type="number"  isInvalid={errors.Address}
                                      placeholder={translatedForm.EnterValue(255)} className="form-control"  />
                                      {errors.Address && 
                                        <span className="text-danger font-size-13">{errors.Address.message}</span>
                                      }
                                    </Form.Group>*/}

                                    <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.Station} <sup className="text-danger">*</sup></Form.Label>
                                    
                                    <Controller
                                        name="Station"                                
                                        control={control}
                                        rules={{ required :translated.Required }}
                                        render={({ field:{value} }) => (
                                        <Select
                                            isSearchable
                                            value={value}
                                            options={listCompanies}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                            onChange={(item) => {
                                              setValue("Station", {label:item.label, value:item.value})
                                            }}
                                        />
                                        )}
                                    />
                                    {errors.Station && 
                                        <span className="text-danger font-size-13">{errors.Station.message}</span>
                                    }   

                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.VersionSoftware}</Form.Label>
                                    <Form.Control 
                                        {...register("VersionSoftware")}
                                        type="text" readOnly={true} maxLength="100" isInvalid={errors.VersionSoftware} className="form-control"  />
                                        {errors.VersionSoftware && 
                                        <span className="text-danger font-size-13">{errors.VersionSoftware.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.VersionProtocol}</Form.Label>
                                    <Form.Control 
                                        {...register("VersionProtocol")}
                                        type="text" readOnly={true} maxLength="100" isInvalid={errors.VersionProtocol}  className="form-control"  />
                                        {errors.VersionProtocol && 
                                        <span className="text-danger font-size-13">{errors.VersionProtocol.message}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.TypeController}</Form.Label>
                                    <Form.Control 
                                        {...register("EnumControllerType")}
                                        type="text" maxLength="100" readOnly={true} className="form-control"  />
                                    </Form.Group>
                                    
                                    <div className="form-group mt-3">
                                      <button type="submit" disabled={btnUpdateMainInfo} className="btn btn-primary">
                                          {btnUpdateMainInfo && (
                                              <Spinner animation="border" variant="light" 
                                                  as="span"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                  style={{ marginRight: "5px" }}
                                              />
                                          )}
                                          {btnUpdateMainInfo && <span>{translatedForm.SaveChanges}</span>}
                                          {!btnUpdateMainInfo && <span>{translatedForm.SaveChanges}</span>}
                                      </button>
                                    </div>
                                </>
                              }
                            </div>

                            <div className="col-xs-12 col-lg-5 col-md-5">
                              
                            {
                                isLoading
                                ? <Skeleton height={30} count={6} className="mb-4" />
                                :
                                <>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="text-black">{translated.FuelIntake}</Form.Label>
                                    
                                    <Controller
                                        name="ModeIntake"                                
                                        control={control}
                                        rules={{ required: translated.Required}}
                                        render={({ field: {value} }) => (
                                        <Select 
                                            onChange={(item) => {
                                              console.log("controller mode intake")
                                              console.log(item)
                                              //setSelectedModeIntake({label:item.name, value:item.value})
                                              setValue("ModeIntake", {label:item.label, value:item.value})
                                            }}
                                            value={value}
                                            options={listBooleans}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.ModeIntake && 
                                        <span className="text-danger font-size-13">{errors.ModeIntake.message}</span>
                                    }
                                  </Form.Group>

                                  {
                                    controllerInfo.type != 3
                                    ?
                                    <Form.Group className="mb-3 mt-3">
                                      <Form.Label className="text-black">{translated.TRKWork}</Form.Label>
                                      
                                      <Controller
                                          name="StopMode"                                
                                          control={control}
                                          rules={{ required: translated.Required }}
                                          render={({ field:{value} }) => (
                                          <Select 
                                              onChange={(item) => {
                                                console.log("controller stop mode")
                                                console.log(item)
                                                if(controllerInfo.type != 3)
                                                {
                                                  setValue("StopMode", {label:item.label, value:item.value})
                                                }
                                                else
                                                {
                                                  setValue("StopMode", {label:item.label, value:item.value})
                                                }
                                              }}
                                              value={value}
                                              options={listBooleans}          
                                              placeholder={translated.SelectFromList}
                                              classNamePrefix="select"
                                          />
                                          )}
                                      />
                                      {errors.StopMode && 
                                          <span className="text-danger font-size-13">{errors.StopMode.message}</span>
                                      }
                                    </Form.Group>
                                    : null
                                  }

                                  <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.RfidReader}</Form.Label>
                                    
                                    <Controller
                                        name="ModeRfid"                                
                                        control={control}
                                        rules={{ required: translated.Required }}
                                        render={({ field: {value} }) => (
                                        <Select 
                                            onChange={(item) => {
                                              //setSelectedModeRfid({label:item.name, value:item.value})
                                              setValue("ModeRfid", {label:item.label, value:item.value})
                                            }}
                                            value={value}
                                            options={listRfid}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.ModeRfid && 
                                        <span className="text-danger font-size-13">{errors.ModeRfid.message}</span>
                                    }
                                  </Form.Group>

                                  <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.OperationMode}</Form.Label>
                                    
                                    <Controller
                                        name="ModeFilling"                                
                                        control={control}
                                        rules={{ required: translated.Required }}
                                        render={({ field : {value}}) => (
                                        <Select 
                                            onChange={(item) => {
                                                //setSelectedModeFilling({label:item.name, value:item.value})
                                                setValue("ModeFilling", {label:item.label, value:item.value})
                                            }}
                                            value={value}
                                            options={listTypeWork}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.ModeFilling && 
                                        <span className="text-danger font-size-13">{errors.ModeFilling.message}</span>
                                    }
                                  </Form.Group>

                                  <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.RemoveSurvey}</Form.Label>
                                    
                                    <Controller
                                        name="DoScanning"                                
                                        control={control}
                                        rules={{ required: translated.Required}}
                                        render={({ field: {value} }) => (
                                        <Select 
                                            onChange={(item) => {
                                              //setSelectedScanning({label:item.name, value:item.value})
                                              setValue("DoScanning", {label:item.label, value:item.value})
                                            }}
                                            value={value}
                                            options={listStopMode}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.DoScanning && 
                                        <span className="text-danger font-size-13">{errors.DoScanning.message}</span>
                                    }
                                  </Form.Group>

                                  {
                                    controllerInfo.type != 3
                                    ?
                                    <Form.Group className="mb-3 mt-3">
                                      <Form.Label className="text-black">{translated.Topaz20}</Form.Label>
                                      
                                      <Controller
                                          name="ModeTopaz"                                
                                          control={control}
                                          rules={{ required: translated.Required }}
                                          render={({ field: {value} }) => (
                                          <Select 
                                              onChange={(item) => {
                                                //setSelectedTopaz({label:item.name, value:item.value})
                                                setValue("ModeTopaz", {label:item.label, value:item.value})
                                              }}
                                              value={value}
                                              options={listBooleans}          
                                              placeholder={translated.SelectFromList}
                                              classNamePrefix="select"
                                          />
                                          )}
                                      />
                                      {errors.ModeTopaz && 
                                          <span className="text-danger font-size-13">{errors.ModeTopaz.message}</span>
                                      }
                                    </Form.Group>
                                    : null
                                  }

                                  {
                                    controllerInfo.type != 3
                                    ?
                                    <Form.Group className="mb-3 mt-3">
                                      <Form.Label className="text-black">{translated.InterfacePanel}</Form.Label>
                                      
                                      <Controller
                                          name="InterfacePanel"                                
                                          control={control}
                                          rules={{ required: translatedForm.Required}}
                                          render={({ field: {value} }) => (
                                          <Select 
                                              onChange={(item) => {
                                                //setSelectedPanel({label:item.name, value:item.value})
                                                setValue("InterfacePanel", {label:item.label, value:item.value})
                                              }}
                                              value={value}
                                              options={listPanel}          
                                              placeholder={translatedForm.SelectFromList}
                                              classNamePrefix="select"
                                          />
                                          )}
                                      />
                                      {errors.InterfacePanel && 
                                          <span className="text-danger font-size-13">{errors.InterfacePanel.message}</span>
                                      }
                                    </Form.Group>
                                    : null
                                  }

                                {
                                  controllerInfo.type == 3
                                  ?
                                  <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="text-black">{translated.OnIndAndKey}</Form.Label>
                                    
                                    <Controller
                                        name="OnIndAndKey"                                
                                        control={control}
                                        render={({ field : {value}}) => (
                                        <Select 
                                            onChange={(item) => {
                                                setValue("OnIndAndKey", {label:item.label, value:item.value})
                                            }}
                                            value={value}
                                            options={listBooleans}          
                                            placeholder={translated.SelectFromList}
                                            classNamePrefix="select"
                                        />
                                        )}
                                    />
                                    {errors.ModeFilling && 
                                        <span className="text-danger font-size-13">{errors.ModeFilling.message}</span>
                                    }
                                  </Form.Group>
                                  : null
                                }
                                  
                                </>
                              }
                            </div>
                        </div>
                      </Form>
                  </Tab.Pane>

                  <Tab.Pane eventKey="sections">
                    {
                      currentActiveTab == "sections"
                      ? <Sections translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} typeController={typeController}/>
                      : null 
                    }
                  </Tab.Pane>
                  
                  <Tab.Pane eventKey="doze">
                    {
                      currentActiveTab == "doze"
                      ? <ControllerFilling translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} typeController={typeController}/>
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="printer">
                    {
                      currentActiveTab == "printer"
                      ? <Printer translated={translated} translatedForm={translatedForm} typeController={typeController}/>
                      : null
                    }
                  </Tab.Pane>
              
                  <Tab.Pane eventKey="param-trk">
                    {
                      controllerInfo.type == 3
                      ? currentActiveTab == "param-trk" && typeController == 1
                        ? <Livenka translated={translated} translatedForm={translatedForm} smartData={smartData} controllerInfo={controllerInfo} />
                        : currentActiveTab == "param-trk" && typeController == 2 
                          ? <Topaz translated={translated} translatedForm={translatedForm} smartData={smartData} countDispenser={countDispenser} listSmartData={listSmartData} controllerInfo={controllerInfo} />
                          : currentActiveTab == "param-trk" && typeController == 3 
                            ? <Gilbarco translated={translated} translatedForm={translatedForm} smartData={smartData} countDispenser={countDispenser} controllerInfo={controllerInfo} />
                            : currentActiveTab == "param-trk" && typeController == 4
                            ? <Tokheim translated={translated} translatedForm={translatedForm} listSmartData={listSmartData} controllerInfo={controllerInfo} />
                            : null
                      : currentActiveTab == "param-trk" && typeController == 2
                        ? <Livenka smartData={smartData} controllerInfo={controllerInfo} />
                        : currentActiveTab == "param-trk" && typeController == 3 
                          ? <Topaz smartData={smartData} controllerInfo={controllerInfo} />
                          : currentActiveTab == "param-trk" && typeController == 4 
                            ? <Gilbarco smartData={smartData} />
                            : null
                    }
                  </Tab.Pane>                                 

                  <Tab.Pane eventKey="nasos">
                    {
                      currentActiveTab == "nasos"
                      ? <ControllerPump translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} />
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="external-input">
                    {
                      currentActiveTab == "external-input"
                      ? <ExternalInput translated={translated} translatedForm={translatedForm} />
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="valve">
                    {
                      currentActiveTab == "valve"
                      ? <ControllerValve translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo}/>
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="flow-sensor">
                    {
                      currentActiveTab == "flow-sensor"
                      ? <ControllerFlowSensor translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} />
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="button-start-stop">
                    {
                      currentActiveTab == "button-start-stop"
                      ? <ControllerButton translated={translated} translatedForm={translatedForm} controllerInfo={controllerInfo} />
                      : null
                    }
                  </Tab.Pane>
                  
                  {
                    controllerInfo.type != 3
                    ?
                    <Tab.Pane eventKey="indicator">
                      {
                        currentActiveTab == "indicator"
                        ? <Indicator translated={translated} translatedForm={translatedForm} />
                        : null
                      }
                    </Tab.Pane>
                    : null
                  }

                  <Tab.Pane eventKey="metrolog">
                    {
                      currentActiveTab == "metrolog"
                      ? <Metrolog translated={translated} translatedForm={translatedForm} />
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="gsm_modem">
                    {
                      currentActiveTab == "gsm_modem"
                      ? <GsmModem translated={translated} translatedForm={translatedForm} />
                      : null
                    }
                  </Tab.Pane>

                  <Tab.Pane eventKey="auth">
                    {
                      currentActiveTab == "auth"
                      ? <Auth translated={translated} translatedForm={translatedForm} />
                      : null
                    }
                  </Tab.Pane>

              </Tab.Content>
          </div>

        </Tab.Container>
      </div>
    )
}